<template>
  <div class="daily-discount-swiper-sales-active" :style="BgColorTheme">
    <div class="image-sty-active" @click="toBanner(item.id)">
      <div style="position: relative">
        <div class="daily-discount-wrap"></div>
        <div class="daily-discount-shop-content">
          <div class="daily-discount-shop-content-title">
            <div class="daily-discount-shop-content-title-content">
              {{ item.title }}
            </div>
            <div class="daily-discount-shop-content-num">
              {{ item.inventory }}份
            </div>
          </div>
          <div class="daily-discount-price-content">
            <div class="daily-discount-price-content-shop">
              <span>商城价</span>
              <span style="text-decoration: line-through; margin-left: 3px"
                >¥{{ item.official_price }}
              </span>
            </div>
            <div
              style="
                height: 10px;
                opacity: 1;
                border-left: 1px solid #cccccc;
                margin: 0 8px;
              "
            ></div>
            <div class="daily-discount-price-content-offcial">
              <span>官方价</span>
              <span>¥{{ item.bazaar_price }}</span>
            </div>
          </div>
          <template>
            <div class="daily-discount-discount-price" v-if="item.type == 0">
              <div class="daily-discount-discount-price-disprice">
                <span
                  style="
                    font-size: 9px;
                    font-weight: 800;
                    color: #f43241;
                    margin-right: 2px;
                  "
                  >¥</span
                >
                <span
                  style="font-size: 16px; font-weight: 800; color: #f43241"
                  >{{ item.price }}</span
                >
              </div>
              <div class="daily-discount-discount-price-disprice-word">
                折扣价
              </div>
            </div>
            <div
              class="daily-discount-discount-price-gold"
              v-else-if="item.type == 1"
            >
              <div class="daily-discount-discount-price-disprice-gold">
                <span
                  style="
                    font-size: 9px;
                    font-weight: 800;
                    color: #754413;
                    margin-right: 2px;
                  "
                  >¥</span
                >
                <span
                  style="font-size: 16px; font-weight: 800; color: #754413"
                  >{{ item.price }}</span
                >
              </div>
              <div class="daily-discount-discount-price-disprice-word-gold">
                会员专享
              </div>
            </div>
            <div
              class="daily-discount-discount-price-pre"
              v-else-if="item.type == 2 && !item.gold_unique"
            >
              <div class="daily-discount-discount-price-disprice-pre">
                <span
                  style="
                    font-size: 9px;
                    font-weight: 800;
                    color: #1c89ff;
                    margin-right: 2px;
                  "
                  >¥</span
                >
                <span
                  style="font-size: 16px; font-weight: 800; color: #1c89ff"
                  >{{ item.price }}</span
                >
              </div>
              <div class="daily-discount-discount-price-disprice-word-pre">
                即将开售
              </div>
            </div>
            <div
              class="daily-discount-discount-price-gold"
              v-else-if="item.type == 2 && item.gold_unique"
            >
              <div class="daily-discount-discount-price-disprice-gold">
                <span
                  style="
                    font-size: 9px;
                    font-weight: 800;
                    color: #754413;
                    margin-right: 2px;
                  "
                  >¥</span
                >
                <span
                  style="font-size: 16px; font-weight: 800; color: #754413"
                  >{{ item.price }}</span
                >
              </div>
              <div class="daily-discount-discount-price-disprice-word-gold">
                会员专享
              </div>
            </div>
          </template>
        </div>
        <img
          :src="item.image"
          class="swiper-item-img"
          alt=""
          :style="SwiperImgWidth"
        />
      </div>
      <template v-if="item.type != 2">
        <div class="countdown-daily-discount">
          {{ times }}
        </div>
        <div class="daily-discount-redcard-content">
          <div
            class="daily-discount-redcard-content-box-word"
            :style="wordColor"
          >
            {{ discount_progressWord }}
          </div>
          <div style="position: relative">
            <div class="daily-discount-redcard-content-box"></div>
            <div :style="progressPresent" class="progress-present-style"></div>
          </div>
          <div class="daily-discount-redcard-content-right">
            <div
              class="daily-discount-redcard-content-right-persent"
              :style="presentBgColor"
            >
              -{{ item.discount_progress }}%
            </div>
            <div class="daily-discount-redcard-content-right-tobuy">
              <div :style="buyColor">去抢购</div>
              <img
                v-if="item.type == 0"
                src="https://cdn.vgn.cn/static/newnewnew/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%402x%20(1).png"
                alt=""
              />
              <img
                v-else
                src="https://cdn.vgn.cn/static/newnewnew/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%20(7).png"
                alt=""
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="countdown-content">
          <div class="countdown-daily-discount-spc">{{ times }}</div>
          <div class="countdown-daily-discount-ready" :style="GoldUnique">
            <div>即将开抢</div>
            <template v-if="showFlag">
              <img
                v-if="!item.gold_unique"
                src="https://cdn.vgn.cn/static/downloadpage/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%20(10).png"
                alt=""
              />
              <img
                v-else
                src="https://cdn.vgn.cn/static/downloadpage/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%20(11).png"
                alt=""
              />
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import InteractionLib from "@/utils/interaction.lib";
import { countdown } from "@/utils/common.util";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SwiperCardItem extends Vue {
  @Prop() item;
  currentIndex = 0;
  BannerDatas = null;
  times = `距结束还有 0天00:00:00`;

  changeImg(index) {
    this.currentIndex = index;
  }

  get discount_progressWord() {
    if (this.item.progress == 100) return "已抢光";
    if (!this.item.progress) return `已抢0%`;
    return `已抢${this.item.progress}%`;
  }

  get GoldUnique() {
    if (this.item.type == 2 && this.item.gold_unique) return `color:#FFCB66`;
  }

  get SwiperImgWidth() {
    const { clientWidth } = document.body;
    return `width:${clientWidth - 36}px;height:155px`;
  }

  get wordColor() {
    if (this.item.type == 0) return `color:#D20000`;
    if (this.item.type == 1) return `color:#9A5300`;
  }

  get buyColor() {
    if (this.item.type == 1) return `color:#FFCB66`;
    return "";
  }

  get progressPresent() {
    if (this.item.type == 1)
      return `width:${this.item.progress}%;background: linear-gradient(90deg, #FFEBB2 0%, #FFCB66 100%);`;
    if (this.item.type == 0)
      return `width:${this.item.progress}%;background: #FFC5BA;`;
  }

  timeFlag = null;
  showFlag = null;

  initCountDown() {
    if (this.item.type == 0 || this.item.type == 1) {
      this.timeFlag = setInterval(() => {
        const { day, hour, minute, second } = countdown(this.item.finish_time);
        this.times = `距结束还有 ${day}天${hour}:${minute}:${second}`;
      }, 1000);
    } else {
      this.timeFlag = setInterval(() => {
        const { day, hour, minute, second } = countdown(this.item.start_time);
        if (this.item.price == "?" && day == 0 && hour < 9) {
          if (hour == 8) {
            if (minute < 30) {
              this.init();
              this.showFlag = true;
            }
          } else if (hour < 8) {
            this.init();
            this.showFlag = true;
          }
        }
        if (this.item.price != "?" && day == 0 && hour < 9) {
          if (hour == 8) {
            if (minute < 30) {
              this.showFlag = true;
            }
          } else if (hour < 8) {
            this.showFlag = true;
          }
        }
        if (
          this.item.price != "?" &&
          day == 0 &&
          hour == 0 &&
          minute == 0 &&
          second == 0
        ) {
          this.init();
          clearInterval(this.timeFlag);
          this.initCountDown();
        }

        this.times = `距开抢还有 ${day}天${hour}:${minute}:${second}`;
      }, 1000);
    }
  }

  created() {
    this.initCountDown();
  }

  @Emit()
  init() {
    return;
  }

  get presentBgColor() {
    if (this.item.type == 1) {
      return `background: #434242;color:#FFE9AD`;
    }
  }

  get BgColorTheme() {
    if (this.item.type == 0) {
      return `background: linear-gradient(135deg, #FA5E41 0%, #F43041 100%);`;
    } else if (this.item.type == 1) {
      return `background: linear-gradient(90deg, #333333 0%, #020202 100%);`;
    }
    if (this.item.gold_unique) {
      return `background: linear-gradient(90deg, #333333 0%, #020202 100%);`;
    }
    return `background: linear-gradient(133deg, #38DCFA 0%, #1677FF 100%);`;
  }

  toBanner(id) {
    if (this.item.price == "?") return;
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sp-store/${id}`);
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${id}` },
    });
  }
}
</script>

<style lang="scss" scoped>
.swiper-item-img {
  object-fit: cover;
  display: block;
  -webkit-user-drag: none;
  margin: 0 auto;
  border-radius: 2px;
}

.daily-discount-redcard-content-box-word {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 9px;
  left: 60px;
  font-weight: 800;
  z-index: 11;
}

.progress-present-style {
  position: absolute;
  border-radius: 4px;
  z-index: 1;
  height: 94%;
  height: 100%;
  top: 0;
  left: 0;
}

.countdown-daily-discount-ready {
  font-size: 14px;
  font-weight: 800;
  color: white;
  display: flex;
  align-items: center;
}

.countdown-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 10px;
}

.countdown-daily-discount-spc {
  font-size: 11px;
  font-weight: 800;
  color: #fff;
}

.daily-discount-swiper {
  margin-top: 12px;
  margin-left: 12px;

  .van-swipe-item {
    -webkit-user-drag: none;

    .image-sty-active {
      overflow: hidden;
      border-radius: 4px;

      .countdown-daily-discount {
        font-size: 9px;
        color: #fff;
        margin: 6px 4px;
        text-align: left;
      }

      .daily-discount-redcard-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin: 0 4px;

        .daily-discount-redcard-content-box {
          width: 160px;
          height: 24px;
          border-radius: 4px;
          background: #ffe8e3;
          font-size: 9px;
          font-weight: 800;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }

        .daily-discount-redcard-content-right {
          display: flex;
          align-items: center;

          .daily-discount-redcard-content-right-persent {
            width: 37px;
            height: 18px;
            background: #ff7878;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 800;
            border-radius: 4px;
            font-size: 10px;
          }

          .daily-discount-redcard-content-right-tobuy {
            color: white;
            margin-left: 2px;
            font-size: 14px;
            font-weight: 800;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }

  .van-swipe__indicators {
    bottom: -30px;
  }
}

.daily-discount-shop-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 37px;
  left: 8px;

  .daily-discount-shop-content-title {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    margin-right: 5px;

    .daily-discount-shop-content-title-content {
      text-overflow: -o-ellipsis-lastline;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .daily-discount-shop-content-num {
      font-size: 10px;
      color: #281721;
      height: 18px;
      border-radius: 2px;
      padding: 0 6px;
      font-weight: bolder;
      background: white;
      display: flex;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
      flex: 1;
      margin-top: 2px;
      margin-left: 4px;
    }
  }
}

.daily-discount-price-content {
  display: flex;
  align-items: center;
  font-size: 10px;
  color: #fff;
  opacity: 0.55;
  margin-top: 3px;
}

.daily-discount-discount-price {
  display: flex;
  align-items: center;
  margin-top: 9px;

  .daily-discount-discount-price-disprice {
    border-radius: 2px;
    padding: 0 8px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(360deg, #fee5e0 0%, #fef4f7 100%);
  }

  .daily-discount-discount-price-disprice-word {
    background: url("https://cdn.vgn.cn/static/newnewnew/Group%2044%402x.png");
    background-size: 100% 100%;
    width: 40px;
    height: 22px;
    font-size: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -6px;
  }
}

.indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  .dot {
    width: 10px;
    height: 5px;
    background: #0b213a;
    margin-right: 6px;
    border-radius: 44px 44px 44px 44px;
  }

  .dotactive {
    width: 5px;
    height: 5px;
    background: #ccc;
    border-radius: 50%;
    margin-right: 6px;
  }
}

.daily-discount-wrap {
  position: absolute;
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.96) 76%,
    #000000 100%
  );
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.daily-discount-swiper-sales-active {
  box-sizing: border-box;
  border-radius: 6px;
  padding: 4px 4px 0 4px;
  height: 216px;
  margin-right: 6px;

  .flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.daily-discount-discount-price-gold {
  display: flex;
  align-items: center;
  margin-top: 9px;

  .daily-discount-discount-price-disprice-gold {
    padding: 0 12px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    border-radius: 2px;
    background: linear-gradient(90deg, #ffebb2 0%, #ffcb66 100%);
  }

  .daily-discount-discount-price-disprice-word-gold {
    background-size: 100% 100%;
    height: 22px;
    padding: 0 9px;
    color: white;
    font-size: 10px;
    display: flex;
    justify-content: center;
    margin-left: -8px;
    align-items: center;
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background: url("https://cdn.vgn.cn/static/newnewnew/20220506-145538.png");
  }
}

.daily-discount-discount-price-pre {
  display: flex;
  align-items: center;
  margin-top: 9px;

  .daily-discount-discount-price-disprice-pre {
    padding: 0 12px 0 6px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    border-radius: 2px;
    background: linear-gradient(360deg, #eefaff 0%, #effcff 100%);
  }

  .daily-discount-discount-price-disprice-word-pre {
    background-size: 100% 100%;
    height: 22px;
    padding: 0 15px;
    color: white;
    font-size: 10px;
    margin-left: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: 100% 100%;
    border-radius: 0 2px 2px 0;
    background-repeat: no-repeat;
    background: url("https://cdn.vgn.cn/static/newnewnew/Slice%206.png");
  }
}
</style>
