var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"speical-small-card",style:({ height: (_vm.ClientWidth) * 0.36 + 16 + 'px' }),on:{"click":function($event){return _vm.handleOpenURL(_vm.item.id)}}},[_c('div',{staticClass:"speical-small-card-icon",style:({
        width: (_vm.ClientWidth ) * 0.38 + 'px',
        height: (_vm.ClientWidth - 32) * 0.36 + 'px',
      })},[(_vm.isSteamApp)?_c('img',{staticStyle:{"width":"68px","height":"16px","position":"absolute","top":"4px","left":"50%","transform":"translateX(-50%)"},attrs:{"src":"https://cdn.vgn.cn/static/new/imgs/%E6%9C%AA%E6%A0%87%E9%A2%98-1%201%402x.png","alt":""}}):_c('img',{staticStyle:{"width":"68px","height":"16px","position":"absolute","top":"4px","left":"50%","transform":"translateX(-50%)"},attrs:{"src":"https://cdn.vgn.cn/access/admin_cover/img/20220913-173657.png","alt":""}}),_c('img',{staticClass:"speical-small-card-icon-img",style:({
        width: (_vm.ClientWidth) * 0.38 - 16 + 'px',
        height: (_vm.ClientWidth) * 0.2 + 'px',
      }),attrs:{"src":_vm.item.cover,"alt":""}})]),_c('div',{staticClass:"speical-small-card-content",style:({height: (_vm.ClientWidth-32) * 0.36 + 'px' })},[_c('div',{staticClass:"speical-small-card-content-title"},[_vm._v(_vm._s(_vm.item.title))]),_c('div',{staticClass:"speical-small-card-content-price",staticStyle:{"margin":"6px 0 4px 0"}},[_c('div',{staticClass:"speical-small-card-content-price"},[_c('span',{staticClass:"speical-small-card-content-price-word",staticStyle:{"color":"#cccccc","margin-left":"4px"}},[_vm._v("Steam官方价")]),_c('span',{staticClass:"speical-small-card-content-price-word",staticStyle:{"color":"#cccccc","margin-left":"4px","text-decoration":"line-through"}},[_vm._v("¥"+_vm._s(_vm.item.bazaar_price))])]),_c('div',{staticStyle:{"height":"10px","opacity":"1","border-left":"1px solid #cccccc","margin":"0 4px"}}),_c('div',{staticClass:"speical-small-card-content-price-word"},[_c('span',{staticStyle:{"color":"#cccccc","margin-left":"2px"}},[_vm._v("商城价")]),_c('span',{staticStyle:{"text-decoration":"line-through","color":"#ccc"}},[_vm._v("¥"+_vm._s(_vm.item.official_price))])])]),_c('div',[_c('span',{staticClass:"speical-small-card-content-price-tag",staticStyle:{"color":"#F74442","font-size":"9px","font-weight":"800"}},[_vm._v("¥")]),_c('span',{staticClass:"speical-small-card-content-price-price",staticStyle:{"color":"#F74442","font-size":"15px","font-weight":"800"}},[_vm._v(_vm._s(_vm.item.price))]),_c('span',{staticStyle:{"font-size":"10px","color":"#F74442"}},[_vm._v("折扣价")])]),_c('div',{staticClass:"daily-discount-redcard-content",style:(_vm.progressColor)},[_c('div',{staticClass:"posR",style:({
          width: _vm.ClientWidth<375? (_vm.ClientWidth ) * 0.39  + 'px':(_vm.ClientWidth -24 ) * 0.26  + 'px'
        })},[_c('div',{staticClass:"progressPresentStyle",style:(_vm.progressPresent)}),_c('div',{staticClass:"daily-discount-redcard-content-box",style:(_vm.progressWordBg)}),_c('div',{staticClass:"daily-discount-redcard-content-box-progressWord",style:(_vm.progressWordColor)},[_vm._v(" "+_vm._s(_vm.progress)+" ")])]),_c('div',{staticClass:"daily-discount-redcard-content-right"},[_c('div',{staticClass:"daily-discount-redcard-content-right-persent",style:(_vm.bgColor)},[_vm._v(" "+_vm._s(_vm.present)+"% ")]),_c('div',{staticClass:"daily-discount-redcard-content-right-tobuy"},[(_vm.item.repertory&&!_vm.item.gold_unique)?[_c('div',{style:(_vm.buyColor)},[_vm._v("抢购")]),_c('img',{attrs:{"src":"https://cdn.vgn.cn/static/newnewnew/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%402x%20(3).png","alt":""}})]:(!_vm.item.repertory)?[_c('div',{style:(_vm.buyColor)},[_vm._v("售罄")])]:[_c('div',{style:(_vm.buyColor)},[_vm._v("抢购")]),_c('img',{attrs:{"src":"https://cdn.vgn.cn/static/newnewnew/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%402x%20(2).png","alt":""}})]],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }