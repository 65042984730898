<template>
  <div
    class="speical-small-card"
    :style="{ height: (ClientWidth) * 0.36 + 16 + 'px' }"
    @click="handleOpenURL(item.id)"
  >
   <!-- <img
        :style="{
          width: (ClientWidth - 32) * 0.35 + 'px',
          height: (ClientWidth - 32) * 0.35 + 'px',
        }"
        :src="item.cover"
        alt=""
        class="speical-small-card-icon"
      /> -->
    <div class="speical-small-card-icon"
      :style="{
          width: (ClientWidth ) * 0.38 + 'px',
          height: (ClientWidth - 32) * 0.36 + 'px',
        }">
        <img v-if="isSteamApp" src="https://cdn.vgn.cn/static/new/imgs/%E6%9C%AA%E6%A0%87%E9%A2%98-1%201%402x.png" style="width:68px;height:16px;position:absolute;top:4px;left:50%;transform:translateX(-50%)" alt="">
        <img v-else src="https://cdn.vgn.cn/access/admin_cover/img/20220913-173657.png" style="width:68px;height:16px;position:absolute;top:4px;left:50%;transform:translateX(-50%)" alt="">
        <img
        :style="{
          width: (ClientWidth) * 0.38 - 16 + 'px',
          height: (ClientWidth) * 0.2 + 'px',
        }"
        :src="item.cover"
        alt=""
        class="speical-small-card-icon-img"
      />
    </div>
    <div
      class="speical-small-card-content"
      :style="{height: (ClientWidth-32) * 0.36 + 'px' }"
    >
      <div class="speical-small-card-content-title">{{ item.title }}</div>
      <div class="speical-small-card-content-price" style="margin:6px 0 4px 0;">
        <div class="speical-small-card-content-price">
          
          <span class="speical-small-card-content-price-word" style="color: #cccccc; margin-left: 4px;">Steam官方价</span>
          <span class="speical-small-card-content-price-word" style="color: #cccccc; margin-left: 4px;text-decoration: line-through">¥{{item.bazaar_price}}</span>
        </div>
        <div
          style="
            height: 10px;
            opacity: 1;
            border-left: 1px solid #cccccc;
            margin: 0 4px;
          "
        ></div>
        <div class="speical-small-card-content-price-word">
         <span style="color: #cccccc; margin-left: 2px">商城价</span>
          <span style="text-decoration: line-through; color: #ccc"
            >¥{{ item.official_price }}</span
          >
        </div>
      </div>
      <div>
      <span class="speical-small-card-content-price-tag" style="color:#F74442;font-size:9px;font-weight:800;">¥</span>
          <span class="speical-small-card-content-price-price" style="color:#F74442;font-size:15px;font-weight:800;">{{
            item.price
          }}</span>
          <span style="font-size:10px;color:#F74442;">折扣价</span>
        </div>
      <div class="daily-discount-redcard-content" :style="progressColor">
        <div
          :style="{
            width: ClientWidth<375? (ClientWidth ) * 0.39  + 'px':(ClientWidth -24 ) * 0.26  + 'px'
          }"
          class="posR"
        >
          <div :style="progressPresent" class="progressPresentStyle">

          </div>
          <div :style="progressWordBg" class="daily-discount-redcard-content-box">

          </div>
          <div :style="progressWordColor" class="daily-discount-redcard-content-box-progressWord">
            {{ progress }}
          </div>
        </div>
        <div class="daily-discount-redcard-content-right">
          <div
            class="daily-discount-redcard-content-right-persent"
            :style="bgColor"
          >
            {{ present }}%
          </div>
          <div class="daily-discount-redcard-content-right-tobuy">
            <template v-if="item.repertory&&!item.gold_unique">
              <div :style="buyColor">抢购</div>
              <img
                src="https://cdn.vgn.cn/static/newnewnew/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%402x%20(3).png"
                alt=""
              />
            </template>
            <template v-else-if="!item.repertory">
              <div :style="buyColor">售罄</div>
            </template>
            <template v-else>
              <div :style="buyColor">抢购</div>
              <img
                src="https://cdn.vgn.cn/static/newnewnew/%E5%9B%BE%E6%A0%87-%E6%9B%B4%E5%A4%9A%402x%20(2).png"
                alt=""
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InteractionLib from "@/utils/interaction.lib";
import { Component, Prop, Vue } from "vue-property-decorator";
import GlobalStateStore from "@/store/modules/global-state.store";
import { getModule } from "vuex-module-decorators";
@Component({
  components: {},
})
export default class SpecialSmallCard extends Vue {
  @Prop() item;
  globalState = getModule(GlobalStateStore);

  get ClientWidth() {
    const { clientWidth } = document.body;
    if (clientWidth < 375) {
      return 310;
    }
    return clientWidth;
  }
  get present() {
    return (
      Math.floor(-(1-(this.item.price / this.item.bazaar_price))*100 )
    );
  }
  get isSteamApp(){
    if(this.globalState.packageName == 'com.vgn.gamepower'){
      return false
    }
    return true
  }
  get progress() {
    if(!this.item.repertory)return '已抢光'
    if(this.item.repertory == this.item.inventory) return `已抢0%`;
    return `已抢${100-Math.floor((this.item.repertory / this.item.inventory) * 100) }%`;
  }
  get progressColor() {
    const {clientWidth} = document.body

    if (!this.item.repertory) {
      return `background:#EBEBEB;width:${clientWidth*0.46}px;`;
    }
    if (this.item.gold_unique) {
      return `background: linear-gradient(90deg, #333333 0%, #020202 100%);width:${clientWidth*0.46}px`;
    }

    return  `background: linear-gradient(315deg, #fa5e41 0%, #f43041 100%);width:${clientWidth*0.46}px`
  }
  get buyColor(){
    if (!this.item.repertory) {
      return `color:#969696`;
    }
    if (this.item.gold_unique) {
      return `color: #FFCB66`;
    }
    return `color:#fff`;
  }
    handleOpenURL(id){
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/sp-store/${id}`
      );
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${id}` },
    });
  }
  get bgColor() {
    if (!this.item.repertory) {
      return `background: #BBBBBB;color:#FFFFFF`;
    }
    if (this.item.gold_unique) {
      return `background: #434242;color: #FFE9AD`;
    }
    return `background: #FF7878`;
  }
  get progressWordColor() {
     if (!this.item.repertory) {
      return `color: white;`;
    }
    if (this.item.gold_unique) {
      return `color: #9A5300;`;
    }

    return `color: #D20000;`;
  }

  get progressWordBg() {
    if (!this.item.repertory) {
      return `background: #CCCCCC;`;
    }
    if (this.item.gold_unique) {
      return `background: #8A7F62;`;
    }

    return `background: #FFE9E6;`;
  }
  get progressPresent(){
    if(this.item.repertory == this.item.inventory) return
    const progress =100- Math.ceil((this.item.repertory / this.item.inventory) * 1000) / 10
    if (!this.item.repertory) {
      return `background: #BBBBBB;width:${progress}%;height:30px;border-radius:2px`;
    }
    if (this.item.gold_unique) {
      return `background: linear-gradient(90deg, #FFEBB2 0%, #FFCB66 100%);width:${progress}%;height:30px;border-radius:2px`;
    }

    return `background: #FFC5BA;width:${progress}%;height:30px;border-radius:2px`;
  }
  created(){
    console.log(this.ClientWidth,'1-0i39812-3')
  }
}
</script>

<style lang="scss" scoped>
.posR{
  position: relative;
}
.daily-discount-redcard-content-box-progressWord{
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-70%,-50%);
  font-size: 9px;
  font-weight: 800;
  white-space: nowrap;
}

.speical-small-card {
  box-shadow: 0px 1px 9px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  background: white;
  margin-top: 10px;
  display: flex;
  align-items: center;
  .speical-small-card-icon {
    margin-left: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    background: url('https://cdn.vgn.cn/static/new/imgs/%E5%85%A8%E7%BD%91%E4%BD%8E%E4%BB%B7%402x.png');
    background-size: 100% 100% ;
    .speical-small-card-icon-img{
      border-radius: 2px;
      margin-top: 25px;
      object-fit: cover;
    }
  }
  .speical-small-card-content {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    justify-content: space-between;
    .speical-small-card-content-title {
      font-size: 13px;
      font-weight: bolder;
      color: #212832;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      width:auto;
      line-height: 16px;
      padding-right: 9px;
      overflow : hidden;
      text-overflow: ellipsis;
      display:-webkit-box;  
      -moz-hyphens: none;
      -ms-hyphens: none;
      -webkit-hyphens: none;
      hyphens: none;
    }
    .speical-small-card-content-price {
      display: flex;
      align-items: center;
      .speical-small-card-content-price-tag {
        font-size: 12px;
        font-weight: 800;
        white-space: nowrap;
        color: #f74442;
      }
      .speical-small-card-content-price-price {
        font-weight: 800;
        font-size: 18px;
        color: #f74442;
        white-space: nowrap;
        margin-top: -3px;
      }
      .speical-small-card-content-price-word {
        font-size: 10px;
        font-weight: 700;
        white-space: nowrap;
        color: #f74442;
      }
    }
  }
}
.daily-discount-redcard-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  border-radius: 2px;
  .daily-discount-redcard-content-box {
    height: 30px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
  }
  .daily-discount-redcard-content-right {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    .daily-discount-redcard-content-right-persent {
      background: #ff7878;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 800;
      border-radius: 2px;
      font-size: 10px;
      padding: 2px;
      margin-left: 4px;
    }
    .daily-discount-redcard-content-right-tobuy {
      color: white;
      margin-left: 5px;
      font-size: 12px;
      white-space: nowrap;
      font-weight: 800;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
}
.progressPresentStyle{
  position: absolute;
}
</style>
