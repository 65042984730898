<template>
  <div style="margin:0 12px;">
    <Swipe
      class="daily-discount-swiper"
      indicator-color="white"
      :show-indicators="false"
      @change="changeImg"
      :autoplay="3000"
    >
      <SwipeItem v-for="(item, index) in BannerDatas" :key="index">
        <SwiperCardItem :item="item" @init="init"/>
      </SwipeItem>
    </Swipe>
    <div class="indicators">
      <div
        :class="{
          dot: index == currentIndex ? true : false,
          dotactive: index == currentIndex ? false : true,
        }"
        v-for="(item, index) in BannerDatas"
        :key="index"
      ></div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { Swipe, SwipeItem } from "vant";
import { getBannerSwiperDetail } from "@/api/activity.blind-box.api";
import SwiperCardItem from "@/views/daily-discount/components/SwiperCardItem.vue";
@Component({
  components: { Swipe, SwipeItem, SwiperCardItem },
})
export default class SwiperCard extends Vue {
  currentIndex = 0;
  BannerDatas = [];
  changeImg(index) {
    this.currentIndex = index;
  }
  async init(){
    this.BannerDatas = await getBannerSwiperDetail();
  }

  created() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.daily-discount-swiper {
  margin-top: 12px;
  .van-swipe-item {
    -webkit-user-drag: none;
  }

  .van-swipe__indicators {
    bottom: -30px;
  }
}

.indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  .dot {
    width: 10px;
    height: 5px;
    background: #0b213a;
    margin-right: 6px;
    border-radius: 44px 44px 44px 44px;
  }
  .dotactive {
    width: 5px;
    height: 5px;
    background: #ccc;
    border-radius: 50%;
    margin-right: 6px;
  }
}
</style>